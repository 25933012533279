import React from 'react';

import * as styles from './index.module.css';

const ArticleLede = ({ lede }) => {
  return (
    <div className={styles['root']}>
      <p className={styles['lede']}>{lede.lede}</p>
    </div>
  );
};

export default ArticleLede;
