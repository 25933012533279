import React from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import CategoryLinkBadge from '../../common/CategoryLinkBadge';
import DateLabel from '../../common/DateLabel';
import PRBadge from '../../common/PRBadge';
import ProvidedBySpoAnBadge from '../../common/ProvidedBySpoAnBadge';

import * as styles from './index.module.css';

const ArticleCategoryAndPublishedAt = ({
  category,
  publishedAt,
  featuredType,
  providedBySpoAn,
}) => {
  return (
    <div className={styles['root']}>
      <div className={styles['leftArea']}>
        <InlineBlockWrapper className={styles['categoryBadgeWrapper']}>
          <CategoryLinkBadge category={category} />
        </InlineBlockWrapper>
        <InlineBlockWrapper className={styles['labelWrapper']}>
          <DateLabel date={publishedAt} />
        </InlineBlockWrapper>
      </div>

      {(() => {
        if (featuredType === 'advertorial') {
          return (
            <div className={styles['rightArea']}>
              <InlineBlockWrapper className={styles['prBadgeWrapper']}>
                <PRBadge />
              </InlineBlockWrapper>
            </div>
          );
        }

        if (providedBySpoAn) {
          return (
            <div className={styles['rightArea']}>
              <InlineBlockWrapper
                className={styles['providedBySpoanBadgeWrapper']}
              >
                <ProvidedBySpoAnBadge />
              </InlineBlockWrapper>
            </div>
          );
        }

        return null;
      })()}
    </div>
  );
};

export default ArticleCategoryAndPublishedAt;
