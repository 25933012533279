import React from 'react';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';

import * as styles from './index.module.css';

const ArticleExpertNameplate = ({ expert }) => {
  const { image, name, title } = expert;

  return (
    <div className={styles['root']}>
      <div className={styles['imageWrapper']}>
        <ObjectFitCoverImage
          src={image.resize.src}
          alt=""
          width={160}
          height={160}
          loading="lazy"
        />
      </div>
      <div>
        <div>
          <span className={styles['name']}>{name} 氏</span>
        </div>
        {title && (
          <div>
            <span className={styles['title']}>{title}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleExpertNameplate;
