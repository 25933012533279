import React from 'react';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import ArticleTermLink from '../ArticleTermLink';

import * as styles from './index.module.css';

const ArticleTermLinkGroup = ({ terms }) => {
  return (
    <div className={styles['root']}>
      {terms.map(term => (
        <InlineBlockWrapper className={styles['termWrapper']} key={term.id}>
          <ArticleTermLink term={term} />
        </InlineBlockWrapper>
      ))}
    </div>
  );
};

export default ArticleTermLinkGroup;
