import React from 'react';

import * as styles from './index.module.css';

const ArticleTitle = ({ title }) => {
  return (
    <div>
      <h1 className={styles['title']}>{title}</h1>
    </div>
  );
};

export default ArticleTitle;
