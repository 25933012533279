import React from 'react';

import { ReactComponent as TagIcon } from '../../../images/icon-tag.svg';

import * as styles from './index.module.css';

const ArticleTagLinkBadgeGroupLabel = () => {
  return (
    <div className={styles['root']}>
      <div className={styles['iconCircleWrapper']}>
        <div className={styles['iconCircle']}>
          <TagIcon />
        </div>
      </div>
      <div className={styles['labelWrapper']}>
        <span className={styles['label']}>タグ一覧</span>
      </div>
    </div>
  );
};

export default ArticleTagLinkBadgeGroupLabel;
