import React from 'react';

import * as styles from './index.module.css';

const ArticleTermsHeading = () => {
  return (
    <div>
      <h2 className={styles['heading']}>この記事で使われている用語</h2>
    </div>
  );
};

export default ArticleTermsHeading;
