import React from 'react';

import TagLinkBadge from '../TagLinkBadge';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const TagLinkBadgeGroup = ({ tags }) => {
  return (
    <div className={styles['root']}>
      {tags.map(tag => (
        <InlineBlockWrapper className={styles['badgeWrapper']} key={tag.id}>
          <TagLinkBadge tag={tag} />
        </InlineBlockWrapper>
      ))}
    </div>
  );
};

export default TagLinkBadgeGroup;
