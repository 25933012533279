import React from 'react';

import * as styles from './index.module.css';

const SectionHeading = ({ heading }) => {
  return (
    <div>
      <h2 className={styles['heading']}>{heading}</h2>
    </div>
  );
};

export default SectionHeading;
