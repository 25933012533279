import React from 'react';

import ArticleExpertNameplate from '../ArticleExpertNameplate';

import * as styles from './index.module.css';

import './index.css';

const ArticleExpertProfile = ({ role, expert }) => {
  return (
    <div className={`article-expert-profile ${styles['root']}`}>
      <div className={styles['roleWrapper']}>
        <span className={styles['role']}>{role}</span>
      </div>
      <div className={styles['nameplateWrapper']}>
        <ArticleExpertNameplate expert={expert} />
      </div>
      <div>
        <p
          className={styles['profile']}
          dangerouslySetInnerHTML={{ __html: expert.profile.profile }}
        />
      </div>
    </div>
  );
};

export default ArticleExpertProfile;
