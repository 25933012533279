import React from 'react';

import DisplayAdvertisement from '../DisplayAdvertisement';

import * as styles from './index.module.css';

const DisplayAdvertisementList = ({ displayAdvertisements }) => {
  return (
    <div className={styles['root']}>
      {displayAdvertisements.map(displayAdvertisement => (
        <div
          key={`display_advertising_${displayAdvertisement.id}`}
          className={styles['displayAdvertisementWrapper']}
        >
          <DisplayAdvertisement displayAdvertisement={displayAdvertisement} />
        </div>
      ))}
    </div>
  );
};

export default DisplayAdvertisementList;
