import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
} from 'react';

import ArticleTermPopover from '../ArticleTermPopover';

const ArticleBodyWithScripts = ({ body, terms }) => {
  const [popoverTerm, setPopoverTerm] = useState(undefined);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(undefined);
  const [isInit, setIsInit] = useState(false);
  const [scriptStrings, setScriptStrings] = useState([]);

  const rootRef = useRef();

  const bodyHTMLString = useMemo(() => {
    const scriptStrings = body.body.match(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    );

    return scriptStrings.reduce((acc, current) => {
      return acc.replace(current, '');
    }, body.body);
  }, [body]);

  const isPopoverOpen = useMemo(() => !!popoverTerm && !!popoverAnchorEl, [
    popoverTerm,
    popoverAnchorEl,
  ]);

  const onTermClick = useCallback(
    event => {
      const target = event.target;
      const termSlug = target.dataset.popover;
      const term = terms.find(term => term.slug === termSlug);

      if (term) {
        setPopoverTerm(term);
        setPopoverAnchorEl(target);
      }
    },
    [terms]
  );

  const onPopoverClose = useCallback(() => {
    setPopoverTerm(undefined);
    setPopoverAnchorEl(undefined);
  }, []);

  useLayoutEffect(() => {
    if (!rootRef.current || scriptStrings.length === 0) {
      return;
    }

    (async () => {
      let bodyHTMLString = body.body;

      await scriptStrings.reduce(async (acc, current) => {
        await acc;

        const scriptFragment = document
          .createRange()
          .createContextualFragment(current);
        const scriptElement = scriptFragment.querySelector('script');

        if (scriptElement.src === '') {
          return Promise.resolve();
        }

        bodyHTMLString = bodyHTMLString.replace(current, '');

        if (
          Array.from(document.querySelectorAll('script')).some(
            se => se.src === scriptElement.src
          )
        ) {
          return Promise.resolve();
        }

        return new Promise(resolve => {
          scriptElement.addEventListener('load', () => {
            resolve();
          });

          document.head.appendChild(scriptElement);
        });
      }, Promise.resolve());

      const bodyFragment = document
        .createRange()
        .createContextualFragment(bodyHTMLString);

      rootRef.current.innerHTML = '';
      rootRef.current.appendChild(bodyFragment);

      setIsInit(true);
    })();
  }, [body, scriptStrings]);

  useLayoutEffect(() => {
    if (!isInit || !rootRef.current) {
      return;
    }

    let targets = [];
    targets = Array.from(rootRef.current.querySelectorAll('[data-popover]'));
    targets.forEach(target => {
      target.addEventListener('click', onTermClick);
    });

    return () => {
      targets.forEach(target => {
        target.removeEventListener('click', onTermClick);
      });
    };
  }, [isInit, onTermClick]);

  useEffect(() => {
    const scriptStrings = body.body.match(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
    );

    setScriptStrings(scriptStrings);
  }, [body]);

  return (
    <Fragment>
      <div
        className="article-body-root"
        ref={rootRef}
        dangerouslySetInnerHTML={{
          __html: bodyHTMLString,
        }}
      />
      <ArticleTermPopover
        term={popoverTerm}
        anchorEl={popoverAnchorEl}
        isOpen={isPopoverOpen}
        onClose={onPopoverClose}
      />
    </Fragment>
  );
};

export default ArticleBodyWithScripts;
