import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';

import ArticleTermPopover from '../ArticleTermPopover';

const ArticleBodyDefault = ({ body, terms }) => {
  const [popoverTerm, setPopoverTerm] = useState(undefined);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(undefined);

  const rootRef = useRef();

  const isPopoverOpen = useMemo(() => !!popoverTerm && !!popoverAnchorEl, [
    popoverTerm,
    popoverAnchorEl,
  ]);

  const onTocLinkClick = useCallback(event => {
    event.preventDefault();

    const hash = event.target.hash;
    const heading = document.getElementById(hash.slice(1));
    if (!heading) {
      return;
    }

    const windowWidth = window.innerWidth;
    const scrollTopGap = windowWidth >= 576 ? 144 : 32;

    window.scrollTo({
      top: heading.offsetTop - scrollTopGap,
      behavior: 'smooth',
    });
  }, []);

  const onTermClick = useCallback(
    event => {
      const target = event.target;
      const termSlug = target.dataset.popover;
      const term = terms.find(term => term.slug === termSlug);

      if (term) {
        setPopoverTerm(term);
        setPopoverAnchorEl(target);
      }
    },
    [terms]
  );

  const onPopoverClose = useCallback(() => {
    setPopoverTerm(undefined);
    setPopoverAnchorEl(undefined);
  }, []);

  useLayoutEffect(() => {
    if (!rootRef.current) {
      return;
    }

    let targets = [];
    targets = Array.from(rootRef.current.querySelectorAll('[data-popover]'));
    targets.forEach(target => {
      target.addEventListener('click', onTermClick);
    });

    return () => {
      targets.forEach(target => {
        target.removeEventListener('click', onTermClick);
      });
    };
  }, [onTermClick]);

  useLayoutEffect(() => {
    if (!rootRef.current) {
      return;
    }

    const tocLinks = Array.from(rootRef.current.querySelectorAll('.toc a'));
    tocLinks.forEach(tocLink => {
      tocLink.addEventListener('click', onTocLinkClick);
    });

    return () => {
      tocLinks.forEach(tocLink => {
        tocLink.removeEventListener('click', onTocLinkClick);
      });
    };
  }, [onTocLinkClick]);

  return (
    <Fragment>
      <div
        className="article-body-root"
        ref={rootRef}
        dangerouslySetInnerHTML={{
          __html: body.body,
        }}
      />
      <ArticleTermPopover
        term={popoverTerm}
        anchorEl={popoverAnchorEl}
        isOpen={isPopoverOpen}
        onClose={onPopoverClose}
      />
    </Fragment>
  );
};

export default ArticleBodyDefault;
