import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Portal } from 'react-portal';

import * as styles from './index.module.css';

const ArticleTermPopover = ({ term, anchorEl, isOpen, onClose }) => {
  const [popoverStyles, setPopoverStyles] = useState({});

  const text = useMemo(() => {
    if (!term) {
      return undefined;
    }

    return term.overview
      ? term.overview.overview
      : term.description.description;
  }, [term]);

  const hasDetail = useMemo(() => {
    if (!term) {
      return false;
    }

    if (
      !term.overview &&
      !term.figure &&
      !term.referenceUrl &&
      !term.relatedTerms
    ) {
      return false;
    }

    return true;
  }, [term]);

  const onBackgroundClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onCloseButtonClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onBackgroundKeyUp = useCallback(
    event => {
      if (onClose) {
        switch (event.code) {
          case 27: // Escape
            onClose();
            break;
          default:
            break;
        }
      }
    },
    [onClose]
  );

  const onWindowScroll = useCallback(() => {
    if (!anchorEl) {
      setPopoverStyles({});
      return;
    }

    const rect = anchorEl.getBoundingClientRect();
    const innerWidth = window.innerWidth;

    let left = rect.left;
    if (left + 280 > innerWidth - 16) {
      left = innerWidth - 280 - 16;
    }

    setPopoverStyles({
      top: rect.bottom + 4,
      left: left,
    });
  }, [anchorEl, setPopoverStyles]);

  useEffect(() => {
    if (!anchorEl) {
      setPopoverStyles({});
      return;
    }

    const rect = anchorEl.getBoundingClientRect();
    const innerWidth = window.innerWidth;

    let left = rect.left;
    if (left + 280 > innerWidth - 16) {
      left = innerWidth - 280 - 16;
    }

    setPopoverStyles({
      top: rect.bottom + 4,
      left: left,
    });

    window.addEventListener('scroll', onWindowScroll);

    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, [anchorEl, onWindowScroll, setPopoverStyles]);

  if (!isOpen || !term || !anchorEl) {
    return null;
  }

  return (
    <Portal>
      <Fragment>
        <div
          className={styles['background']}
          role="button"
          aria-label="閉じる"
          onClick={onBackgroundClick}
          onKeyUp={onBackgroundKeyUp}
          tabIndex={0}
        />
        <div className={styles['popover']} style={popoverStyles}>
          <div className={styles['popoverContent']}>
            <div className={styles['popoverBody']}>
              <p>{text}</p>
            </div>
            <div className={styles['popoverFooter']}>
              <div className={styles['anchorWrapper']}>
                {hasDetail && (
                  <div className={styles['anchorWrapper']}>
                    <a
                      className={styles['anchor']}
                      href={`/term/${term.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      さらに詳しく
                    </a>
                  </div>
                )}
              </div>
              <div className={styles['closeButtonWrapper']}>
                <button
                  className={styles['closeButton']}
                  onClick={onCloseButtonClick}
                >
                  ×<span className="sr-only">閉じる</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </Portal>
  );
};

export default ArticleTermPopover;
