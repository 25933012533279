import React from 'react';

import TagLinkBadgeGroup from '../../common/TagLinkBadgeGroup';

import ArticleTagLinkBadgeGroupLabel from '../ArticleTagLinkBadgeGroupLabel';

import * as styles from './index.module.css';

const ArticleTagLinkBadgeGroup = ({ tags }) => {
  return (
    <div className={styles['root']}>
      <div className={styles['labelWrapper']}>
        <ArticleTagLinkBadgeGroupLabel />
      </div>
      <TagLinkBadgeGroup tags={tags} />
    </div>
  );
};

export default ArticleTagLinkBadgeGroup;
