// extracted by mini-css-extract-plugin
export var aside = "index-module--aside--g65Ax";
export var bodyWrapper = "index-module--body-wrapper--yeFEw";
export var bottomArticleShareButtonGroup = "index-module--bottom-article-share-button-group--RVfKT";
export var categoryAndPublishedAtWrapper = "index-module--category-and-published-at-wrapper--Kld0w";
export var displayAdvertisingWrapper = "index-module--display-advertising-wrapper--W3S9k";
export var expertProfileGroupWrapper = "index-module--expert-profile-group-wrapper--GR26h";
export var ledeWrapper = "index-module--lede-wrapper--gN8D2";
export var main = "index-module--main--6ZvBK";
export var mainImageWrapper = "index-module--main-image-wrapper--xBSUt";
export var nav = "index-module--nav--3wC8C";
export var sectionHeadingWrapper = "index-module--section-heading-wrapper--j9Vjk";
export var seriesLinkWrapper = "index-module--series-link-wrapper--Sqf+v";
export var tagLinkBadgeGroupWrapper = "index-module--tag-link-badge-group-wrapper--rV8v4";
export var termsHeadingWrapper = "index-module--terms-heading-wrapper--MbKYQ";
export var termsLinkWrapper = "index-module--terms-link-wrapper--9qq+G";
export var titleWrapper = "index-module--title-wrapper--jwSUM";
export var topArticleShareButtonGroup = "index-module--top-article-share-button-group--Di1yO";