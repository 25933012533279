import React from 'react';
import PropTypes from 'prop-types';
import {
  TwitterShareButton,
  FacebookShareButton,
  LineShareButton,
  TwitterIcon,
  FacebookIcon,
  LineIcon,
} from 'react-share';
import { useStaticQuery, graphql } from 'gatsby';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import * as styles from './index.module.css';

const ArticleShareButtonGroup = ({ path, title }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  const url = `${site.siteMetadata.siteUrl}${path}`;

  return (
    <div className={styles['root']}>
      <InlineBlockWrapper className={styles['shareButtonWrapper']}>
        <TwitterShareButton url={url} title={title}>
          <TwitterIcon size={36} round={true} />
        </TwitterShareButton>
      </InlineBlockWrapper>

      <InlineBlockWrapper className={styles['shareButtonWrapper']}>
        <FacebookShareButton url={url} quote={title}>
          <FacebookIcon size={36} round={true} />
        </FacebookShareButton>
      </InlineBlockWrapper>

      <InlineBlockWrapper className={styles['shareButtonWrapper']}>
        <LineShareButton url={url} title={title}>
          <LineIcon size={36} round={true} />
        </LineShareButton>
      </InlineBlockWrapper>
    </div>
  );
};

ArticleShareButtonGroup.prototype = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ArticleShareButtonGroup;
