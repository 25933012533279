import React, { useState, useCallback } from 'react';
import { Link } from 'gatsby';

import InlineBlockWrapper from '../../../components/InlineBlockWrapper';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';
import PRBadge from '../../common/PRBadge';
import ProvidedBySpoAnBadge from '../../common/ProvidedBySpoAnBadge';

import * as styles from './index.module.css';

const ArticleListCardSmall = ({ article }) => {
  const [linkStyle, setLinkStyle] = useState({});

  const { title, slug, mainImage, featuredType, providedBySpoAn } = article;

  const onMouseEnterLink = useCallback(() => {
    setLinkStyle({
      opacity: 0.75,
    });
  }, [setLinkStyle]);

  const onMouseLeaveLink = useCallback(() => {
    setLinkStyle({});
  }, [setLinkStyle]);

  return (
    <div className={styles['root']}>
      <Link
        className={styles['link']}
        to={`/article/${slug}`}
        style={linkStyle}
        onMouseEnter={onMouseEnterLink}
        onMouseLeave={onMouseLeaveLink}
      >
        <div className={styles['mainImageWrapper']}>
          <ObjectFitCoverImage
            src={mainImage.resize.src}
            alt=""
            width={600}
            height={315}
            loading="lazy"
          />
        </div>

        <div className={styles['contentWrapper']}>
          <div>
            <span className={styles['title']}>{title}</span>
          </div>

          {(() => {
            if (featuredType === 'advertorial') {
              return (
                <InlineBlockWrapper className={styles['prBadgeWrapper']}>
                  <PRBadge />
                </InlineBlockWrapper>
              );
            }

            if (providedBySpoAn) {
              return (
                <InlineBlockWrapper
                  className={styles['providedBySpoanBadgeWrapper']}
                >
                  <ProvidedBySpoAnBadge />
                </InlineBlockWrapper>
              );
            }

            return null;
          })()}
        </div>
      </Link>
    </div>
  );
};

export default ArticleListCardSmall;
