import React, { useMemo } from 'react';

import ArticleBodyDefault from './ArticleBodyDefault';
import ArticleBodyWithScripts from './ArticleBodyWithScripts';

import './index.css';

const ArticleBody = ({ body, terms }) => {
  const hasScript = useMemo(() => {
    return /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi.test(
      body.body
    );
  }, [body]);

  if (!hasScript) {
    return <ArticleBodyDefault body={body} terms={terms} />;
  } else {
    return <ArticleBodyWithScripts body={body} terms={terms} />;
  }
};

export default ArticleBody;
