import React from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const ArticleTagLinkBadge = ({ tag }) => {
  return (
    <div className={styles['root']}>
      <Link className={styles['label']} to={`/tag/${tag.slug}`}>
        {tag.name}
      </Link>
    </div>
  );
};

export default ArticleTagLinkBadge;
