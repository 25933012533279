import React from 'react';
import 'ress/ress.css';
import '../../styles/variables.css';
import '../../styles/base.css';

import PublicRoot from '../../components/PublicRoot';
import SEO from '../../components/SEO';
import PageDefault from '../../components/PageDefault';
import ContentWrapper from '../../components/ContentWrapper';
import ContainerDefault from '../../components/ContainerDefault';
import InlineBlockWrapper from '../../components/InlineBlockWrapper';

import ArticleListSmall from '../../organisms/ArticleListSmall';
import SiteHeader from '../../organisms/SiteHeader';
import SiteFooter from '../../organisms/SiteFooter';
import GlobalNavigation from '../../organisms/GlobalNavigation';

import ArticleCategoryAndPublishedAt from '../../molecules/article/ArticleCategoryAndPublishedAt';
import ArticleSeriesLink from '../../molecules/article/ArticleSeriesLink';
import ArticleTitle from '../../molecules/article/ArticleTitle';
import ArticleShareButtonGroup from '../../molecules/article/ArticleShareButtonGroup';
import ArticleMainImage from '../../molecules/article/ArticleMainImage';
import ArticleLede from '../../molecules/article/ArticleLede';
import ArticleTagLinkBadgeGroup from '../../molecules/article/ArticleTagLinkBadgeGroup';
import ArticleBody from '../../molecules/article/ArticleBody';
import ArticleExpertProfileGroup from '../../molecules/article/ArticleExpertProfileGroup';
import ArticleTermsHeading from '../../molecules/article/ArticleTermsHeading';
import ArticleTermLinkGroup from '../../molecules/article/ArticleTermLinkGroup';

import Breadcrumb from '../../molecules/common/Breadcrumb';
import ContentArea from '../../molecules/common/ContentArea';
import SectionHeading from '../../molecules/common/SectionHeading';
import DisplayAdvertisementList from '../../molecules/common/DisplayAdvertisementList';

import * as styles from './index.module.css';

const ArticleTemplate = ({ pageContext }) => {
  const { pagePath, article, relatedArticles } = pageContext;

  const breadcrumbItems = [
    {
      to: '/',
      label: 'ホーム',
    },
    {
      to: '/category',
      label: 'カテゴリー一覧',
    },
    {
      to: `/category/${article.category.slug}`,
      label: article.category.name,
    },
    {
      to: `/article/${article.slug}`,
      label: article.title,
    },
  ];

  return (
    <PublicRoot>
      <SEO
        path={pagePath}
        title={article.title}
        description={article.description.description}
        ogImage={
          article.ogpImage
            ? article.ogpImage.resize.src
            : article.mainImage.resize.src
        }
        featuredType={article.featuredType}
      />
      <SiteHeader />

      <PageDefault>
        <ContentWrapper>
          <main className={styles['main']}>
            <nav className={styles['nav']}>
              <ContainerDefault>
                <Breadcrumb items={breadcrumbItems} />
              </ContainerDefault>
            </nav>

            <article>
              <ContainerDefault>
                <ContentArea>
                  <div className={styles['categoryAndPublishedAtWrapper']}>
                    <ArticleCategoryAndPublishedAt
                      category={article.category}
                      publishedAt={article.publishedAt}
                      featuredType={article.featuredType}
                      providedBySpoAn={article.providedBySpoAn}
                    />
                  </div>

                  {article.series && (
                    <InlineBlockWrapper className={styles['seriesLinkWrapper']}>
                      <ArticleSeriesLink series={article.series} />
                    </InlineBlockWrapper>
                  )}

                  <div className={styles['titleWrapper']}>
                    <ArticleTitle title={article.title} />
                  </div>

                  <div className={styles['topArticleShareButtonGroup']}>
                    <ArticleShareButtonGroup
                      path={pagePath}
                      title={article.title}
                    />
                  </div>

                  <div className={styles['mainImageWrapper']}>
                    <ArticleMainImage mainImage={article.mainImage} />
                  </div>

                  <div className={styles['ledeWrapper']}>
                    <ArticleLede lede={article.lede} />
                  </div>

                  {article.interviewees && (
                    <section className={styles['expertProfileGroupWrapper']}>
                      <h2 className="sr-only">インタビュイー</h2>
                      <ArticleExpertProfileGroup
                        role="インタビュイー"
                        experts={article.interviewees}
                      />
                    </section>
                  )}

                  {article.teachers && (
                    <section className={styles['expertProfileGroupWrapper']}>
                      <h2 className="sr-only">講師</h2>
                      <ArticleExpertProfileGroup
                        role="講師"
                        experts={article.teachers}
                      />
                    </section>
                  )}

                  {article.moderators && (
                    <section className={styles['expertProfileGroupWrapper']}>
                      <h2 className="sr-only">進行</h2>
                      <ArticleExpertProfileGroup
                        role="進行"
                        experts={article.moderators}
                      />
                    </section>
                  )}

                  {article.authors && (
                    <section className={styles['expertProfileGroupWrapper']}>
                      <h2 className="sr-only">筆者</h2>
                      <ArticleExpertProfileGroup
                        role="筆者"
                        experts={article.authors}
                      />
                    </section>
                  )}

                  {article.tags && (
                    <div className={styles['tagLinkBadgeGroupWrapper']}>
                      <ArticleTagLinkBadgeGroup tags={article.tags} />
                    </div>
                  )}

                  <div className={styles['bodyWrapper']}>
                    <ArticleBody body={article.body} terms={article.terms} />
                  </div>

                  {article.displayAdvertisements && (
                    <div className={styles['displayAdvertisingWrapper']}>
                      <DisplayAdvertisementList
                        displayAdvertisements={article.displayAdvertisements}
                      />
                    </div>
                  )}

                  <div className={styles['bottomArticleShareButtonGroup']}>
                    <ArticleShareButtonGroup
                      path={pagePath}
                      title={article.title}
                    />
                  </div>

                  {article.terms && (
                    <div className={styles['termsLinkWrapper']}>
                      <div className={styles['termsHeadingWrapper']}>
                        <ArticleTermsHeading />
                      </div>
                      <div>
                        <ArticleTermLinkGroup terms={article.terms} />
                      </div>
                    </div>
                  )}
                </ContentArea>
              </ContainerDefault>
            </article>
          </main>

          {relatedArticles.length > 0 && (
            <aside className={styles['aside']}>
              <section>
                <ContainerDefault>
                  <div className={styles['sectionHeadingWrapper']}>
                    <SectionHeading heading="合わせて読みたい" />
                  </div>

                  <div>
                    <ArticleListSmall articles={relatedArticles} />
                  </div>
                </ContainerDefault>
              </section>
            </aside>
          )}
        </ContentWrapper>
      </PageDefault>

      <GlobalNavigation />

      <SiteFooter />
    </PublicRoot>
  );
};

export default ArticleTemplate;
