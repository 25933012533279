import React from 'react';

import ObjectFitCoverImage from '../../common/ObjectFitCoverImage';

import * as styles from './index.module.css';

const ArticleMainImage = ({ mainImage }) => {
  return (
    <div className={styles['root']}>
      <ObjectFitCoverImage
        src={mainImage.resize.src}
        alt=""
        width={1200}
        height={630}
      />
    </div>
  );
};

export default ArticleMainImage;
