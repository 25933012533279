import React from 'react';

import ArticleListCardSmall from '../../molecules/article/ArticleListCardSmall';

import * as styles from './index.module.css';

const ArticleListSmall = ({ articles }) => {
  return (
    <div>
      <ul className={styles['list']}>
        {articles.map(article => (
          <li className={styles['listItem']} key={article.id}>
            <ArticleListCardSmall article={article} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleListSmall;
