import React, { useCallback } from 'react';

import ObjectFitCoverImage from '../ObjectFitCoverImage';

import * as styles from './index.module.css';

const DisplayAdvertisement = ({ displayAdvertisement }) => {
  const {
    title,
    description,
    buttonLabel,
    url,
    thumbnail,
    conversionId,
  } = displayAdvertisement;

  const onAnchorClick = useCallback(() => {
    if (!window.dataLayer) {
      return;
    }

    window.dataLayer.push({
      event: 'display_advertisement_conversion',
      GA_ConversionId: conversionId,
    });
  }, [conversionId]);

  return (
    <div className={styles['root']}>
      <div className={styles['imageWrapper']}>
        <ObjectFitCoverImage
          src={thumbnail.resize.src}
          alt=""
          width={200}
          height={200}
        />
      </div>
      <div className={styles['contentWrapper']}>
        <div>
          <div className={styles['titleWrapper']}>
            <span className={styles['title']}>{title}</span>
          </div>
          {description && (
            <div>
              <span className={styles['description']}>{description}</span>
            </div>
          )}
        </div>
        <div className={styles['buttonWrapper']}>
          <div className={styles['button']}>
            <a
              onClick={onAnchorClick}
              className={styles['buttonLabel']}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonLabel}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisplayAdvertisement;
