import React from 'react';

import ArticleExpertProfile from '../ArticleExpertProfile';

import * as styles from './index.module.css';

const ArticleExpertProfileGroup = ({ role, experts }) => {
  return (
    <div>
      {experts.map(expert => (
        <div className={styles['profileWrapper']} key={`aepg_${expert.id}`}>
          <ArticleExpertProfile expert={expert} role={role} />
        </div>
      ))}
    </div>
  );
};

export default ArticleExpertProfileGroup;
