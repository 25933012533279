import React from 'react';
import { Link } from 'gatsby';

import * as styles from './index.module.css';

const ArticleTermLink = ({ term }) => {
  return (
    <div className={styles['root']}>
      <Link className={styles['link']} to={`/term/${term.slug}`}>
        {term.word}
      </Link>
    </div>
  );
};

export default ArticleTermLink;
